import Logo from '../logo/logo'
import styles from './header.module.css'
import arrow from '../../assets/user-arrow.svg'
import logoutImage from '../../assets/logout.svg'
import avatarImage from '../../assets/user-default.png'
import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useAuthStore } from '../../hooks/useAuthStore'
import { listenForOutsideClick } from '../../utils'
import useAuthLogin from '../../hooks/useAuthLogin'

export default function Header() {
    const menuRef = useRef(null)
    const [showUserMenu, setShowUserMenu] = useState(false)
    const [listening, setListening] = useState(false)
    const navigate = useNavigate()
    const authStore = useAuthStore()
    const { logout } = useAuthLogin()
    const avatarURL = authStore.user?.avatarURL ?? avatarImage
    const avatarBg = authStore.user?.avatarURL ? undefined : '#CAC0FF'

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(listenForOutsideClick(
        listening,
        setListening,
        menuRef,
        setShowUserMenu,
    ))

    async function handleLogout(event) {
        event.preventDefault()
        setShowUserMenu(false)
        await logout()
    }

    return (
        <header className={styles.header}>
            <div className={styles.inner}>
                <Logo />

                {authStore.isLoggedIn() && (
                    <div ref={menuRef} className={clsx(styles.user, { [styles.open]: showUserMenu })}>
                        <div className={styles.user_toggle} onClick={() => { setShowUserMenu(!showUserMenu) }}>
                            <img src={avatarURL} style={{ backgroundColor: avatarBg }} width={32} height={32} alt='' className={styles.user_avatar} />
                            <img src={arrow} width={16} height={16} alt='' className={styles.user_arrow} />
                        </div>

                        <div className={styles.user_menu}>
                            <div className={styles.user_card} onClick={() => { setShowUserMenu(false); navigate('/') }}>
                                <img src={avatarURL} style={{ backgroundColor: avatarBg }} width={40} height={40} alt='' className={styles.user_avatar} />
                                <div>
                                    <h5>{authStore.user.username}</h5>
                                    <Link to='/'>Открыть профиль</Link>
                                </div>
                            </div>

                            <div className={styles.user_menu_items}>
                                <Link to='/' className={styles.user_menu_item} onClick={handleLogout}>
                                    <img src={logoutImage} width={16} height={16} alt='' />
                                    Выйти из аккаунта
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </header>
    )
}